<template>
	<v-app-bar dark width="100%">
		<a @click="$router.push({ name: 'home' })">
			<v-avatar class="mr-3" tile>
				<v-img :src="require('@/assets/logo.png')" />
			</v-avatar>
		</a>

		<!-- Hiding title on xs screens -->
		<!-- <v-toolbar-title class="pr-4 d-none d-sm-flex" @click="$router.push({ name: 'home' })"> -->
		<!-- 	{{ appName }} -->
		<!-- </v-toolbar-title> -->

		<div class="flex-grow-1 d-flex justify-center">
			<div
				v-for="option in leftNavOptions"
				v-bind:key="'nav-' + option.path"
				>
				<v-btn :to="{ name: option.path }" plain class="mx-4">
					{{ option.name }}
				</v-btn>
			</div>
		</div>
	</v-app-bar>
</template>
<script>
export default {
	data: () => ({
		appName: "",
	}),
	mounted() {
		this.appName = process.env.VUE_APP_NAME;
		this.$store.dispatch("GET_POSTS");
	},
	computed: {
		leftNavOptions: function(){
			return [
				{
					name: "Home",
					path: "home",
				},
				{
					name: "about",
					path: "about",
				},
				{
					name: "Services",
					path: "services",
				},
				{
					name: "Resume",
					path: "resume",
				},
				(
					// Only show blog link if there is at least 1 blog post
					// TODO: Instead of loading all the posts, get a count from the server
					(
						this.$store.getters.GET_POSTS.loaded
						&& this.$store.getters.GET_POSTS.data.length > 0
					)
					? {
						name: "Blog",
						path: "post-list-view",
					}
					: null
				),
				// {
				// 	name: "Playground",
				// 	path: "playground-home",
				// },
				{
					name: "Contact Me",
					path: "contact-me",
				},
			]
				.filter(nav => nav != null)
		}
	},
};
</script>
