<template>
	<TeaserCard
	max_width="100em"
	:icons="['mdi-information']"
	:title="title"
	:body="body"
	/>
</template>
<script>
import TeaserCard from "@/components/teasers/cards/TeaserCard.vue";
export default {
	components: {
		TeaserCard
	},
	data() {
		return {
			title: "About This Website",
		}
	},
	computed: {
		body: function(){
			let appName = process.env.VUE_APP_NAME;
			let emailLink = "mailto:andrew@lampert.solutions?subject=Request for Sample Code";
			return `<p>This website, <span class="accent--text">${appName}</span>, has been developed to provide additional information to the PDF version of my resume, and an example of my work. The website is used also to calculate professional time spent utilizing each skill and time within each employment position. These statistics are used to automatically generate the PDF version of my resume.</p>
<p>If you're interested in viewing my code, feel free to <a href="${emailLink}">send me an email!</a></p>`;
		}
	},
}
</script>

